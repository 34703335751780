import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { BaseApi, useAppContext } from '../../Context/AppContext';
import { BASEAPIURL, useDashboardContext } from './Context/DashboardContext';

const Package = () => {
  return (
    <div>
      <Tabs />
    </div>
  )
}





const Tabs = () => {
  const [activeTab, setActiveTab] = useState("Tab1");

  const renderContent = () => {
    switch (activeTab) {
      case "Tab1":
        return <CurrentPackage />;
      case "Tab2":
        return <ViewAllPackages />;
      case "Tab3":
        return <div>Content for Tab 3</div>;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="tab-buttons">
        <button
          className={activeTab === "Tab1" ? "active" : ""}
          onClick={() => setActiveTab("Tab1")}
        >
          Current Package
        </button>
        <button
          className={activeTab === "Tab2" ? "active" : ""}
          onClick={() => setActiveTab("Tab2")}
        >
          Packages
        </button>
      </div>
      <div className=" referralBox">{renderContent()}</div>
    </div>
  );
};


const CurrentPackage = () => {
  const { allCurrentPlan } = useDashboardContext();
  const { userInfo } = useAppContext();



  const onClaim = async (id, amount) => {

    if (amount <= 0) {
      toast.error("You Already Claim Your Profit");
    } else {
      const data = {
        id: id,
        amount,
        userId: userInfo?._id
      };
      await BaseApi.post('/user/claim-profit', data)
        .then(r => {
          if (r.status == 200) {
            toast.success("Claim Profit Success");
            window.location.reload();
          }
        }).catch((e) => {
          console.log(e.message);
        });
      // console.log(data);
    }

  }

  return (
    <div>
      <h3>Current Packages</h3>
      <div className=''>
        <table className='market-table '>
          <thead className='table-head'>
            <tr className='table-row table-title'>
              <th className="table-heading" scope="col">Id</th>
              <th className="table-heading" scope="col">Plan</th>
              <th className="table-heading" scope="col">Daily Profit %</th>
              <th className="table-heading" scope="col">Status</th>
              <th className="table-heading" scope="col">Date</th>
              <th className="table-heading" scope="col">Action</th>

            </tr>
          </thead>
          <tbody style={{ position: allCurrentPlan?.length > 0 ? "inherit" : "relative" }}>
            {
              allCurrentPlan == null ?
                <p>Please Wait</p> :
                allCurrentPlan?.length > 0
                  ? allCurrentPlan?.map((d, k) => {
                    console.log(d);
                    return (
                      <tr className="table-row" key={k}>
                        <td>{k + 1}</td>
                        <td>{d?.amount != 0 ? d?.amount : (d?.plan?.invest?.from != 0 && d?.plan?.invest?.from)}$ ({d?.plan.type})</td>
                        <td>{d?.profits?.profit ?? 0}$</td>
                        <td>{d?.isApproved}</td>
                        <td>{d?.createdAt.split("T")[0]}</td>
                        <td><button onClick={() => {
                          onClaim(d?._id, d?.profits?.profit);
                        }} className='profile-button'  >Claim</button></td>
                      </tr>
                    );
                  }) : <div style={{
                    position: 'absolute',
                    width: "100%"
                  }}>
                    <div style={{
                      display: 'flex',
                      alignItems: "center",
                      justifyContent: 'center',
                      flexDirection: 'column',
                      marginTop: "4rem"
                    }}>
                      <p>No Data Found</p>
                      <img style={{ width: 100 }} src='images/nodataImage.png' />
                    </div>
                  </div>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}


const ViewAllPackages = () => {


  const { allPlans, userInfo } = useAppContext();
  const yearlyPlans = allPlans?.filter(v => v.type === "yearly");
  const monthlyPlans = allPlans?.filter(v => v.type === "monthly");
  const [isShow, setIsShow] = useState(false);

  const onBuyPlan = async (id, amount) => {
    if (userInfo?.totalAmount < amount) {
      toast.error("Insufficient balance. Please enter an amount less than or equal to your total available balance.");
    } else {

      if (window.confirm("Do you want to buy?")) {
        try {
          const response = await axios.post(`${BASEAPIURL}userByPlan/createPlayByUser`, {
            userId: userInfo?._id,
            amount: amount,
            planId: id,
          });
          if (response.status == 200) {
            toast.success("Plan Buy Successfully");
            window.location.reload();
          }
        } catch (error) {
          console.log(error);
        }
      } else { }
    }
  }

  return <div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between", marginBottom: 20 }}>
      <h3>Monthly Packages</h3>
      <button onClick={() => {
        setIsShow(true)
      }} className='profile-button' style={{ padding: '8px 30px' }}>Custom Package</button></div>
    <div className='userPackagesCardRow'>
      <div className="tab-content" style={{ marginBlock: '1rem', width: "100%" }}>
        {
          monthlyPlans?.map((v, i) => {
            return (
              <div class="package-card" key={i}>
                <div class="header">
                  <div class="amount">${v?.invest?.from}</div>
                </div>
                <div class="percentage">{v?.profit?.from}/{v?.profit?.to}%</div>
                <div class="sub-header">Yearly Investment Profit</div>
                <ul class="benefits">
                  <li><span>✔</span> 4% Direct Reward</li>
                  <li><span>✔</span> 8% Level Income</li>
                  <li><span>✔</span>24/7 Chat Support</li>
                </ul>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                  <button onClick={() => { onBuyPlan(v._id, v?.invest?.from) }} className='profile-button'>Buy Now</button>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
    <h3>Yearly Packages</h3>
    <div className='userPackagesCardRow'>
      <div className="tab-content" style={{ marginBlock: '1rem', width: "100%" }}>
        {
          yearlyPlans?.map((v, i) => {
            return (
              <div class="package-card" key={i}>
                <div class="header">
                  <div class="amount">${v?.invest?.from}</div>
                </div>
                <div class="percentage">{v?.profit?.from}/{v?.profit?.to}%</div>
                <div class="sub-header">Yearly Investment Profit</div>
                <ul class="benefits">
                  <li><span>✔</span> 4% Direct Reward</li>
                  <li><span>✔</span> 8% Level Income</li>
                </ul>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                  <button onClick={() => { onBuyPlan(v._id, v?.invest?.from) }} className='profile-button'>Buy Now</button>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
    <AddProfit isShow={isShow} onClose={() => setIsShow(false)} />
  </div>
}



const AddProfit = ({ isShow, onClose }) => {
  const { userInfo } = useAppContext();
  const [loader, setLoader] = useState(false);
  const [planId, setPlanId] = useState(null);
  const [amount, setAmount] = useState('');
  // const [userIds, setUserIds] = useState(null);
  // const [profit, setProfit] = useState('');


  // const onGetUser = async (id) => {
  //   setPlanId(id);
  //   await BaseApi.get(`/plan/getUserByPlan/${id}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       // if (res?.data?.data?.length > 0) {
  //       setUsers(res?.data?.data);
  //       // }
  //     })
  //     .catch((er) => {
  //       console.log(er);
  //     })
  // }


  // const onSelectUser = (id) => {
  //   const userIds = id == "all" ? users?.map((u) => u?.user?._id) : [id];
  //   setUserIds(userIds)
  // }


  const addProfit = async () => {
    console.log(userInfo?._id)
    setLoader(true);
    await BaseApi.post("/userByPlan/addCustomPlan", { userId: userInfo?._id, amount: amount, planName: planId })
      .then((res) => {
        if (res.status == 200) {
          toast.success("Add Profit Success");
          window.location.reload();
        }
      }).catch(er => {
        console.log(er);
      });
    setLoader(false);
  }
  return (
    <div className={`model-box ${isShow ? "show" : "hide"}`}>
      <div className='loginModel'
        style={{
          height: "auto"
        }}
      >

        <div className='modal-header'>
          <h4>{"Add Custom Package"}</h4>
        </div>
        <div className='modal-body'>
          <div className="form-group">
            <label htmlFor="">Plans</label>
            <div className="field" style={{ paddingInline: 10 }}>
              <select
                onChange={(e) => setPlanId(e.target.value)}
                style={{ width: '100%', border: 'none', outline: 'none', background: "transparent" }}>
                <option>--Select Plan--</option>
                {
                  ["Monthly", "Yearly"]?.map((v, k) => {
                    return <option value={v} key={k}>{v}</option>
                  })
                }
              </select>
            </div>
          </div>



          <div className="form-group">
            <label htmlFor="">Amount ($)</label>
            <div className="field" style={{ paddingInline: 4 }}>
              <input type="number"
                onChange={(e) => { setAmount(e.target.value) }}
                value={amount}
                placeholder="Enter Amount $" style={{ width: '100%', border: 'none', outline: 'none', color: "#111", background: "transparent" }} />
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button onClick={() => {
            onClose();
            // setUsers(null);
            // setProfit('');
            // setPlanId("");

          }}>Cancel</button>
          <button style={{ width: 120 }} onClick={() => {
            // isApprovedOrReject("APPROVED");
            addProfit();
          }}>{loader ? "Please Wait.." : "Buy now"}</button>
        </div>
      </div>
    </div>
  )
}
export default Package