import React, { useState } from 'react'
import DepositTable from './Tables/DepositTable';
import WithdrawTable from './Tables/WithDrawTable';
import ReferralTable from './Tables/ReferralTable';
import DriectTable from './Tables/DirectTable';
import { useDashboardContext } from './Context/DashboardContext';

const Dashboard = () => {
  const { allDirectReward, allDeposit, allWithDraw, allCurrentProfit } = useDashboardContext();

  const totalAmountOfDirect = allDirectReward?.reduce((sum, item) => sum + item.amount, 0);
  const totalAmountOfWithdraw = allWithDraw?.reduce((sum, item) => sum + parseFloat(item.amount), 0);
  const totalAmountOfDeposit = allDeposit?.reduce((sum, item) => sum + parseFloat(item.amount), 0);



  return (
    <div className='dashboard-box'>
      <div className='dashboard-row'>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/deposit-3d-icon-download-in-png-blend-fbx-gltf-file-formats--money-finance-bank-cash-economic-pack-indian-businesses-icons-9168977.png' />
          <div>
            <h4>Total Deposit</h4>
            <p>${parseFloat((totalAmountOfDeposit), 10).toString()}</p>
          </div>
        </div>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/atm-withdrawal-3d-icon-download-in-png-blend-fbx-gltf-file-formats--cash-dispence-machine-dispencing-banking-pack-business-icons-3374139.png' />
          <div>
            <h4>Total Withdraw</h4>
            <p>${parseFloat((totalAmountOfWithdraw), 10).toString()}</p>
          </div>
        </div>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/referral-3d-icon-download-in-png-blend-fbx-gltf-file-formats--refferal-money-flow-financial-network-internet-marketing-pack-seo-web-icons-8135908.png' />
          <div>
            <h4>Referral Profit</h4>
            <p>$0.00</p>
          </div>
        </div>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/deposit-3d-icon-download-in-png-blend-fbx-gltf-file-formats--money-finance-bank-cash-economic-pack-indian-businesses-icons-9168977.png' />

          <div>
            <h4>Direct Reward</h4>
            <p>${parseFloat(totalAmountOfDirect, 10).toString()}</p>
          </div>
        </div>
        <div className='dashboard-card'>
          <img src='https://cdn3d.iconscout.com/3d/premium/thumb/dollar-exchange-3d-icon-download-in-png-blend-fbx-gltf-file-formats--money-currency-foreign-conversion-investment-pack-business-icons-5858264.png?f=webp' />
          <div>
            <h4>Total Profit</h4>
            <p>${allCurrentProfit && allCurrentProfit[0] && allCurrentProfit[0].totalProfit !== null && allCurrentProfit[0].totalProfit !== undefined ? allCurrentProfit[0].totalProfit.toFixed(2) : '0.00'}</p>

          </div>
        </div>

      </div>
      <Tabs />
    </div>
  )
}



const Tabs = () => {
  const [activeTab, setActiveTab] = useState("Tab1");

  const renderContent = () => {
    switch (activeTab) {
      case "Tab1":
        return <DepositTable />;
      case "Tab2":
        return <WithdrawTable />;
      case "Tab3":
        return <ReferralTable />;
      case "Tab4":
        return <DriectTable />;
      default:
        return null;
    }
  };

  return (
    <div className='tab-container' style={{ marginTop: '4rem' }}>
      <div className="tab-buttons">
        <button
          className={activeTab === "Tab1" ? "active" : ""}
          onClick={() => setActiveTab("Tab1")}
        >
          Deposit
        </button>
        <button
          className={activeTab === "Tab2" ? "active" : ""}
          onClick={() => setActiveTab("Tab2")}
        >
          Withdraw
        </button>
        <button
          className={activeTab === "Tab3" ? "active" : ""}
          onClick={() => setActiveTab("Tab3")}
        >
          Referral
        </button>
        <button
          className={activeTab === "Tab4" ? "active" : ""}
          onClick={() => setActiveTab("Tab4")}
        >
          Direct Reward
        </button>
        {/* <button
          className={activeTab === "Tab5" ? "active" : ""}
          onClick={() => setActiveTab("Tab5")}
        >
          History
        </button> */}
      </div>
      <div className=" referralBox">{renderContent()}</div>
    </div>
  );
};
export default Dashboard